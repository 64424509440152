import {
  Content,
  ContentHeader,
  Header,
  // InfoCard,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { ImportStepper } from '@backstage/plugin-catalog-import';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import React from 'react';

import { CustomImportInfoCard } from './CustomImportInfoCard';

const CustomImportPage = () => {
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptional('app.title') || 'Backstage';

  return (
    <Page themeId="home">
      <Header title="Register an existing component" />
      <Content>
        <ContentHeader title={`Start tracking your component in ${appTitle}`}>
          <SupportButton>
            Start tracking your component in {appTitle} by adding it to the
            software catalog.
          </SupportButton>
        </ContentHeader>

        <Grid container spacing={2} direction="row-reverse">
          <Grid item xs={12} md={4} lg={6} xl={8}>
            {/* either create an own "<CustomImportInfoCard />" or simply add the content directly here */}
            <CustomImportInfoCard />
          </Grid>

          <Grid item xs={12} md={8} lg={6} xl={4}>
            {/* this will render the actual "import wizard" of the plugin. Feel free to change the outer page layout to your linking as long as you include the "ImportStepper" */}
            <ImportStepper />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

// it is a common pattern in Backstage to save a single instance into a const variable
export const customImportPage = <CustomImportPage />;
